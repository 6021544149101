module.exports = global.config = {

    // development
    // APP_URL: 'http://192.168.1.16:3001/',
    // ADMIN_APP_URL: 'http://192.168.1.16:3000/',
    // API_URL: 'http://192.168.1.16:6001/',

    // SECRET_KEY: 'be4671fca118a2eac9db6b81e89d078bd1a259c6f8d20ed291946235a12e46ad0d0046059e434d2ab42dbb032aec02f153bfa9e65644ad75691a2653a2ad7602',

    // staging
    APP_URL: 'https://dealpopup.ahsan.cloud/',
    ADMIN_APP_URL: 'https://dealpopupadmin.ahsan.cloud/',
    API_URL: 'https://dealpopupservice.ahsan.cloud/'

    // production
    // APP_URL: 'https://dealpopup.com/',
    // ADMIN_APP_URL: 'https://portal.dealpopup.com/',
    // API_URL: 'https://services.dealpopup.com/'
};