/**
 * 
 * App Schema
 * 
 */

import { lazy } from 'react'
import store from '../store/records'
import moment from 'moment'
import Gallery from 'components/Gallery'
import { user1 } from 'components/ProfileMenu'
import rawCountries from 'helpers/countries'
import ViewLocation from 'components/ViewLocation'
import { checkIsAfter, checkTwoDates, setDateFormat, setLatLongFromVendor, getCountryCodeFromPhoneNo, removeCountryCodeFromPhoneNo, getKeywordFromCategories } from 'helpers/tools'
import default_company_logo from 'assets/images/default_company_logo.png'
import pdf_image from 'assets/images/pdf_image.png'

const countriesList = rawCountries && rawCountries.length > 0 && rawCountries.map(e => Object.assign({}, { label: e[0], value: e[0] }))

/**
 * Import All Routing Component
 */
const RecordsPage = lazy(() => import('pages/Records'))
const CategoriesPage = lazy(() => import('pages/Categories'))
const CreateRecordPage = lazy(() => import('pages/CreateRecord'))
const EditRecordPage = lazy(() => import('pages/EditRecord'))
const SettingsPage = lazy(() => import('pages/Settings'))

const schema = [
    {
        schemaId: 1,
        name: 'vendor',
        title: 'Vendors',
        path: '/vendor',
        visible: true,
        ...store('vendor'),
        component: RecordsPage,
        icon: "fi-rs-building",
        exact: true,
        onSubmitValues: (submitValues) => {
            const values = Object.assign({}, submitValues)
            const lat_long = values.lat_long && values.lat_long.split(",")
            values.latitude = lat_long && lat_long[0]
            values.longitude = lat_long && lat_long[1]
            values.country_code = getCountryCodeFromPhoneNo(values.company_phone_no)
            values.license_expiry_date = moment(values.license_expiry_date).add(1, 'days').format('YYYY-MM-DD')
            values.company_phone_no = removeCountryCodeFromPhoneNo(values.company_phone_no)
            return values
        },
        config: {
            addBtnLabel: 'Add vendor',
            confirmDialogText: 'Do you want to delete this vendor?'
        },
        subscriptionTabs: [
            {
                id: 1,
                label: "Post subscriptions",
                value: "post-subscription"
            },
            {
                id: 2,
                label: "Banner subscriptions",
                value: "banner-subscription"
            }
        ],
        subscriptionHistoryTabs: [
            {
                id: 1,
                label: "Post subscriptions",
                value: "post-subscription-history"
            },
            {
                id: 2,
                label: "Banner subscriptions",
                value: "banner-subscription-history"
            }
        ],
        postSubscriptionColumns: (role, record, { user }) => {
            return [
                {
                    id: 1,
                    value: 'id',
                    label: 'ID',
                    editRecord: false,
                    viewRecord: false,
                    viewMode: false,
                    type: 'text'
                },
                {
                    id: 2,
                    value: 'vendor_id',
                    label: 'Vendor ID',
                    editRecord: false,
                    viewRecord: false,
                    type: 'text'
                },
                {
                    id: 3,
                    value: 'subscription_type',
                    label: 'Subscription type',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    options: [{ value: 'Annual', label: 'Annual' }, { value: 'Monthly', label: 'Monthly' }],
                    placeholder: 'Select Subscription type',
                    type: 'select'
                },
                {
                    id: 4,
                    value: 'subscription_plan',
                    label: 'Subscription plan name',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    placeholder: 'Enter Subscription plan',
                    type: 'text'
                },
                {
                    id: 5,
                    value: 'subscription_features',
                    label: 'Subscription features',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    placeholder: 'Enter subscription features',
                    type: 'text'
                },
                {
                    id: 6,
                    value: 'notes',
                    label: 'Subscription notes',
                    editRecord: true,
                    viewRecord: true,
                    required: false,
                    sort: false,
                    placeholder: 'Enter notes',
                    type: 'textarea'
                },
                {
                    id: 7,
                    value: 'collected_amount',
                    label: 'Collected amount',
                    editRecord: true,
                    viewRecord: true,
                    required: false,
                    sort: false,
                    placeholder: 'Enter collected amount',
                    type: 'text'
                },
                {
                    id: 8,
                    value: 'collection_date',
                    label: 'Collected date',
                    editRecord: true,
                    viewRecord: true,
                    required: false,
                    sort: false,
                    formatter: (val) => moment(val).format('MM/DD/YYYY'),
                    defaultValue: record && setDateFormat(record.collection_date || user?.serverTime),
                    placeholder: 'Enter collected date',
                    type: 'date'
                },
                {
                    id: 9,
                    value: 'start_date',
                    label: 'Plan start date',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    formatter: (val) => moment(val).format('MM/DD/YYYY'),
                    defaultValue: record && setDateFormat(record.start_date || user?.serverTime),
                    placeholder: 'Enter plan start date',
                    type: 'date'
                },
                {
                    id: 10,
                    value: 'end_date',
                    label: 'Plan end date',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    formatter: (val) => moment(val).subtract(1, "days").format('MM/DD/YYYY'),
                    defaultValue: record && setDateFormat(record.end_date || user?.serverTime),
                    placeholder: 'Enter plan end date',
                    type: 'date'
                },
                {
                    id: 11,
                    value: 'no_of_post',
                    label: 'Total posts',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    placeholder: 'Enter total posts',
                    type: 'number'
                }
            ]
        },
        bannerSubscriptionColumns: (role, record, { user }) => {
            return [
                {
                    id: 1,
                    value: 'id',
                    label: 'ID',
                    editRecord: false,
                    viewRecord: false,
                    viewMode: false,
                    type: 'text'
                },
                {
                    id: 2,
                    value: 'vendor_id',
                    label: 'Vendor ID',
                    editRecord: false,
                    viewRecord: false,
                    type: 'text'
                },
                {
                    id: 3,
                    value: 'subscription_type',
                    label: 'Subscription type',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    options: [{ value: 'Annual', label: 'Annual' }, { value: 'Monthly', label: 'Monthly' }],
                    placeholder: 'Select subscription type',
                    type: 'select'
                },
                {
                    id: 4,
                    value: 'subscription_plan',
                    label: 'Subscription plan name',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    placeholder: 'Enter subscription plan',
                    type: 'text'
                },
                {
                    id: 5,
                    value: 'subscription_features',
                    label: 'Subscription features',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    placeholder: 'Enter subscription features',
                    type: 'text'
                },
                {
                    id: 6,
                    value: 'notes',
                    label: 'Subscription notes',
                    editRecord: true,
                    viewRecord: true,
                    required: false,
                    sort: false,
                    placeholder: 'Enter notes',
                    type: 'textarea'
                },
                {
                    id: 7,
                    value: 'collected_amount',
                    label: 'Collected amount',
                    editRecord: true,
                    viewRecord: true,
                    required: false,
                    sort: false,
                    placeholder: 'Enter collected amount',
                    type: 'text'
                },
                {
                    id: 8,
                    value: 'collection_date',
                    label: 'Collected date',
                    editRecord: true,
                    viewRecord: true,
                    required: false,
                    sort: false,
                    formatter: (val) => moment(val).format('MM/DD/YYYY'),
                    defaultValue: record && setDateFormat(record.collection_date || user?.serverTime),
                    placeholder: 'Enter Collected Date',
                    type: 'date'
                },
                {
                    id: 9,
                    value: 'start_date',
                    label: 'Plan start date',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    formatter: (val) => moment(val).format('MM/DD/YYYY'),
                    defaultValue: record && setDateFormat(record.start_date || user?.serverTime),
                    placeholder: 'Enter plan start date',
                    type: 'date'
                },
                {
                    id: 10,
                    value: 'end_date',
                    label: 'Plan end date',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    formatter: (val) => moment(val).subtract(1, "days").format('MM/DD/YYYY'),
                    defaultValue: record && setDateFormat(record.end_date || user?.serverTime),
                    placeholder: 'Enter plan end date',
                    type: 'date'
                },
                {
                    id: 11,
                    value: 'no_of_banner',
                    label: 'Total banners',
                    editRecord: true,
                    viewRecord: true,
                    required: true,
                    sort: false,
                    placeholder: 'Enter total banners',
                    type: 'number'
                }
            ]
        },
        columns: (role, record, { user, ...metaData }) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'profile_image',
                label: 'Company logo',
                editRecord: true,
                viewRecord: true,
                required: false,
                sort: true,
                sortValue: "ve.profile_image",
                uploadKey: 'public_url',
                uploadUrl: '/rest/vendor/profile-upload',
                className: 'profile_photo_gallery',
                style: { width: '180px', height: '180px', minHeight: '180px' },
                placeholderStyle: { fontSize: '12px' },
                uploadPercentageStyle: { fontSize: '12px' },
                allowedFiles: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg': ['.svg'],
                    'image/webp': ['.webp'],
                    'image/gif': ['.gif'],
                    'image/x-ms-bmp': ['.bmp'],
                    'image/x-icon': ['.ico'],
                    'image/x-jng': ['.jng'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/avif': ['.avif']
                },
                formatter: (imageUrl) => <Gallery items={[<img src={imageUrl || user1} alt="profile" onError={(e) => e.target.src = user1} />]}>{(toggler) => <img src={imageUrl || default_company_logo} onError={(e) => e.target.src = default_company_logo} className='profile_photo_gallery' alt="profile photo" onClick={toggler} />}</Gallery>,
                decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="profile" />]}>{(toggler) => <img src={value} alt="profile photo" className='profile_photo_gallery' style={{ width: '180px', height: '180px' }} onClick={toggler} />}</Gallery>,
                type: 'upload'
            },
            {
                id: 3,
                value: 'license_document',
                label: 'Trade license',
                editRecord: true,
                viewRecord: false,
                required: true,
                sort: false,
                viewForm: true,
                uploadKey: 'public_url',
                uploadUrl: '/rest/vendor/license-upload',
                placeholderStyle: { fontSize: '12px' },
                uploadPercentageStyle: { fontSize: '12px' },
                placeholder: 'Upload your license document (only pdf).',
                allowedFiles: {
                    'application/pdf': ['.pdf']
                },
                formatter: (value) => <img src={pdf_image} alt="pdf" width="50" height="50" style={{ cursor: 'pointer' }} onClick={() => window.open(value, '_blank')} />,
                decoratorView: ({ value }) => <i className={`mdi mdi-file-pdf-box d-block`} style={{ fontSize: "95px", cursor: 'pointer' }} onClick={() => window.open(value, '_blank')}></i>,
                type: 'upload'
            },
            {
                id: 4,
                value: 'company_name',
                label: 'Company name',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ve.company_name",
                placeholder: 'Enter company name',
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'text'
            },
            {
                id: 5,
                value: 'contact_person',
                label: 'Contact person',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ve.contact_person",
                placeholder: 'Enter contact person',
                type: 'text'
            },
            {
                id: 6,
                value: 'company_email',
                label: 'Company email',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ve.company_email",
                placeholder: 'Enter company email',
                type: 'text'
            },
            {
                id: 7,
                value: 'company_phone_no',
                label: 'Company phone number',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ve.company_phone_no",
                placeholder: 'Enter company phone number',
                formatter: (v, r) => r.country_code && r.company_phone_no ? `${r.country_code} ${removeCountryCodeFromPhoneNo(r.company_phone_no)}` : '',
                type: 'phone'
            },
            {
                id: 8,
                value: 'license_number',
                label: 'Trade license number',
                editRecord: true,
                viewRecord: false,
                required: true,
                sort: false,
                viewForm: true,
                placeholder: 'Enter license number',
                type: 'text'
            },
            {
                id: 9,
                value: 'license_expiry_date',
                label: 'License expiry date',
                editRecord: true,
                viewRecord: false,
                required: true,
                sort: false,
                defaultValue: record && setDateFormat(record?.license_expiry_date || user?.serverTime),
                placeholder: 'Enter license expiry date',
                type: 'date'
            },
            {
                id: 10,
                value: 'area',
                label: 'Area',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ve.area",
                placeholder: 'Enter area',
                type: 'text'
            },
            {
                id: 11,
                value: 'city',
                label: 'City',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ve.city",
                placeholder: 'Enter city',
                type: 'text'
            },
            {
                id: 12,
                value: 'state',
                label: 'State',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ve.state",
                placeholder: 'Enter state',
                type: 'text'
            },
            {
                id: 13,
                value: 'country',
                label: 'Country',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ve.country",
                options: countriesList,
                placeholder: 'Select country',
                type: 'select'
            },
            {
                id: 14,
                value: 'post_box_no',
                label: 'Post box no.',
                editRecord: true,
                viewRecord: false,
                required: true,
                sort: false,
                viewForm: true,
                placeholder: 'Enter post box no.',
                type: 'number'
            },
            {
                id: 15,
                value: 'notes',
                label: 'Vendor notes',
                editRecord: true,
                viewRecord: false,
                required: false,
                viewForm: true,
                rows: "1",
                placeholder: 'Enter notes',
                type: 'textarea'
            },
            {
                id: 16,
                value: 'lat_long',
                label: 'Address',
                editRecord: true,
                viewRecord: false,
                required: true,
                viewForm: true,
                formatter: (val) => <ViewLocation value={val} />,
                placeholder: 'Enter address',
                type: 'location'
            },
            {
                id: 17,
                value: 'is_inactive',
                label: 'Active status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val, record) => <div className={!val && ((record && !record.license_expiry_date) || (record && record.license_expiry_date && moment(user?.serverTime).diff(moment(record.license_expiry_date), 'days') > 0)) ? 'table_expired' : !val ? 'table_active' : 'table_inactive'}>{!val && ((record && !record.license_expiry_date) || (record && record.license_expiry_date && moment(user?.serverTime).diff(moment(record.license_expiry_date), 'days') > 0)) ? 'Expired' : !val ? 'Active' : 'Inactive'}</div>,
                type: 'text'
            },
            {
                id: 18,
                value: 'license_expiry_date',
                label: 'License expiry date',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "ve.license_expiry_date",
                formatter: (val) => val && moment(val).format('MM/DD/YYYY') || '',
                type: 'text'
            },
            {
                id: 19,
                value: 'post_subscription_no_of_post',
                label: 'Remaining post',
                editRecord: false,
                viewRecord: true,
                sort: false,
                type: 'text'
            },
            {
                id: 20,
                value: 'post_subscription_end_date',
                label: 'Post subscription expiry date',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val) => val && moment(val).subtract(1, "days").format('MM/DD/YYYY') || '',
                type: 'text'
            },
            {
                id: 21,
                value: 'post_subscription_end_date',
                label: 'Post subscription status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val) => {
                    return <div className={val && moment(user?.serverTime).diff(moment(val)) < 0 ? 'table_active' : val && moment(user?.serverTime).diff(moment(val)) > 0 ? 'table_expired' : 'table_inactive'}>
                        {!val ? 'Not Active' : moment(user?.serverTime).diff(moment(val)) < 0 ? 'Active Plan' : moment(user?.serverTime).diff(moment(val)) > 0 ? 'Expired' : ''}
                    </div>
                },
                type: 'text'
            },
            {
                id: 22,
                value: 'banner_subscription_no_of_banner',
                label: 'Remaining banner',
                editRecord: false,
                viewRecord: true,
                sort: false,
                type: 'text'
            },
            {
                id: 23,
                value: 'banner_subscription_end_date',
                label: 'Banner subscription expiry date',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val) => val && moment(val).subtract(1, "days").format('MM/DD/YYYY') || '',
                type: 'text'
            },
            {
                id: 24,
                value: 'banner_subscription_end_date',
                label: 'Banner subscription status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val) => {
                    return <div className={val && moment(user?.serverTime).diff(moment(val)) < 0 ? 'table_active' : val && moment(user?.serverTime).diff(moment(val)) > 0 ? 'table_expired' : 'table_inactive'}>
                        {!val ? 'Not Active' : moment(user?.serverTime).diff(moment(val)) < 0 ? 'Active Plan' : moment(user?.serverTime).diff(moment(val)) > 0 ? 'Expired' : ''}
                    </div>
                },
                type: 'text'
            },
            {
                id: 25,
                value: 'creatorName',
                label: 'Added by',
                editRecord: false,
                viewRecord: true,
                sort: false,
                type: 'text'
            },
            {
                id: 26,
                value: 'createdAt',
                label: 'Created date',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "ve.createdAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 27,
                value: 'updatedAt',
                label: 'Last updated',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "ve.updatedAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 28,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            }
        ],
        children: [
            {
                name: 'create',
                title: 'Vendor',
                path: '/vendor/create',
                visible: true,
                component: CreateRecordPage
            },
            {
                name: 'edit',
                title: 'Vendor',
                path: '/vendor/:id',
                visible: true,
                component: EditRecordPage
            }
        ]
    },
    {
        schemaId: 2,
        name: 'post-categories',
        title: 'Categories',
        path: '/post-categories',
        visible: true,
        ...store('post-categories'),
        component: CategoriesPage,
        icon: "fi-rs-rectangle-list",
        exact: true,
        onSubmitValues: (submitValues) => {
            const values = Object.assign({}, submitValues)
            values.order = 1
            return values
        },
        config: {
            addBtnLabel: 'Add post category',
            confirmDialogText: 'Do you want to delete this post category?'
        },
        columns: (role) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'category_name',
                label: 'Category name',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: false,
                placeholder: 'please enter category name.',
                type: 'text'
            },
            {
                id: 3,
                value: 'parent',
                label: 'Choose Parent Category',
                editRecord: ['superAdmin', 'admin'].includes(role) ? true : false,
                viewRecord: true,
                sort: false,
                options: "postCategories",
                type: 'treeSelect'
            },
            {
                id: 4,
                value: 'keyword',
                label: 'Keyword',
                editRecord: false,
                viewRecord: false,
                required: false,
                sort: false,
                type: 'text'
            },
            {
                id: 5,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            }
        ]
    },
    {
        schemaId: 3,
        name: 'banner',
        title: 'Banners',
        path: '/banner',
        visible: true,
        ...store('banner'),
        component: RecordsPage,
        icon: "fi-ss-ad-paid",
        exact: true,
        config: {
            addBtnLabel: 'Add banner',
            confirmDialogText: 'Do you want to delete this banner?'
        },
        onSubmitValues: (submitValues) => {
            const values = Object.assign({}, submitValues)
            const lat_long = values.lat_long && values.lat_long.split(",")
            values.latitude = lat_long && lat_long[0]
            values.longitude = lat_long && lat_long[1]
            values.notes = values.banner_notes
            values.banner_notes = values.banner_notes
            values.banner_valid_from = values.banner_valid_from && moment(values.banner_valid_from).format('YYYY-MM-DD')
            values.banner_valid_till = values.banner_valid_till && moment(values.banner_valid_till).format('YYYY-MM-DD')
            values.global_display = (values.global_display === 'Y') ? true : false
            return values
        },
        columns: (role, record, { user, ...metaData }, records, sessionId) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'banner_desktop_url',
                label: 'Desktop photo',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ba.banner_desktop_url",
                fullWidth: true,
                uploadKey: 'public_url',
                uploadUrl: '/rest/banner/upload-files',
                allowedFiles: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg': ['.svg'],
                    'image/webp': ['.webp'],
                    'image/gif': ['.gif'],
                    'image/x-ms-bmp': ['.bmp'],
                    'image/x-icon': ['.ico'],
                    'image/x-jng': ['.jng'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/avif': ['.avif']
                },
                placeholder: `Upload Desktop Banner <br/><br/> Home Page - 1322px X 220px <br/> Filter Page Horizontal - 733px X 120px <br/> Filter Page Vertical - 222px X 1186px <br/> Detail Page - 1322px X 220px)`,
                formatter: (imageUrl) => <Gallery items={[<img src={imageUrl} alt="banner" onError={(e) => e.target.src = "https://placehold.co/600x400?text=No+Data"} />]}>{(toggler) => <img src={imageUrl} alt="banner photo" onError={(e) => e.target.src = "https://placehold.co/600x400?text=No+Data"} width="50" height="50" style={{ objectFit: 'contain' }} onClick={toggler} />}</Gallery>,
                decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="banner" />]}>{(toggler) => <img src={value} alt="banner photo" style={{ maxWidth: '1370px', height: '330px', width: '100%', objectFit: 'contain' }} onClick={toggler} />}</Gallery>,
                type: 'upload'
            },
            {
                id: 3,
                value: 'banner_mobile_url',
                label: 'Mobile photo',
                editRecord: record?.page === "filterPageVertical" ? false : true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ba.banner_mobile_url",
                fullWidth: true,
                uploadKey: 'public_url',
                uploadUrl: '/rest/banner/upload-files',
                allowedFiles: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg': ['.svg'],
                    'image/webp': ['.webp'],
                    'image/gif': ['.gif'],
                    'image/x-ms-bmp': ['.bmp'],
                    'image/x-icon': ['.ico'],
                    'image/x-jng': ['.jng'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/avif': ['.avif']
                },
                placeholder: 'Upload Mobile Banner (380px X 150px)',
                formatter: (imageUrl) => imageUrl ? <Gallery items={[<img src={imageUrl} alt="mobile banner" onError={(e) => e.target.src = "https://placehold.co/600x400?text=No+Data"} />]}>{(toggler) => <img src={imageUrl} onError={(e) => e.target.src = "https://placehold.co/600x400?text=No+Data"} alt="mobile banner photo" width="50" height="50" style={{ objectFit: 'contain' }} onClick={toggler} />}</Gallery> : <div style={{ textAlign: 'center' }}>&mdash;</div>,
                decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="mobile banner" />]}>{(toggler) => <img src={value} alt="mobile banner photo" style={{ width: '100%', height: '200px', objectFit: 'contain' }} onClick={toggler} />}</Gallery>,
                type: 'upload'
            },
            {
                id: 4,
                value: 'global_display',
                label: 'Global display',
                editRecord: true,
                viewRecord: false,
                required: false,
                sort: false,
                style: { marginTop: '32px' },
                fullWidth: true,
                placeholder: 'Select global display',
                type: 'checkbox'
            },
            {
                id: 5,
                value: 'vendor_id',
                label: 'Vendor',
                editRecord: role === 'vendor' ? false : true,
                viewRecord: false,
                required: true,
                sort: false,
                disabled: record.id,
                options: record && record.id ? "vendors" : "vendorsOptions",
                placeholder: 'Select vendor',
                formatter: (val, r, i, metaData) => metaData && metaData.vendors && metaData.vendors.length > 0 && metaData.vendors.find(_ => _.id.toString() === val.toString()) && metaData.vendors.find(_ => _.id.toString() === val.toString()).company_name,
                type: 'select'
            },
            {
                id: 6,
                value: 'comany_name',
                label: 'Vendor',
                editRecord: false,
                viewRecord: role === 'vendor' ? false : true,
                required: false,
                sort: true,
                sortValue: "ve.company_name",
                placeholder: "Enter company name",
                formatter: (val, r) => r.company_name && r.company_name.length > 20 ? r.company_name.substring(0, 20) + ' ....' : r.company_name,
                type: 'text'
            },
            {
                id: 7,
                value: 'creatorName',
                label: 'Added by',
                editRecord: false,
                viewRecord: ['superAdmin', 'admin'].includes(role) ? true : false,
                required: false,
                sort: false,
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'input'
            },
            {
                id: 8,
                value: 'page',
                label: 'Page',
                editRecord: true,
                viewRecord: true,
                required: true,
                fullWidth: role === 'vendor' ? true : false,
                sort: true,
                sortValue: "ba.page",
                formatter: (val) => (val === 'homePage') ? 'Home Page' : (val === 'filterPageHorizantal') ? 'Filter Page - Horizontal' : (val === 'filterPageVertical') ? 'Filter Page - Vertical' : (val === 'detailPage') ? 'Detail Page' : val,
                options: [
                    {
                        label: 'Home Page',
                        value: 'homePage'
                    },
                    {
                        label: 'Filter Page - Horizontal',
                        value: 'filterPageHorizantal'
                    },
                    {
                        label: 'Filter Page - Vertical',
                        value: 'filterPageVertical'
                    },
                    {
                        label: 'Detail Page',
                        value: 'detailPage'
                    }
                ],
                placeholder: 'Select page',
                type: 'select'
            },
            {
                id: 9,
                value: 'banner_valid_from',
                label: 'Start date',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ba.banner_valid_from",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                defaultValue: record && setDateFormat(record?.banner_valid_from || user?.serverTime),
                minDate: record && !record.id ? moment(user?.serverTime).format('MM/DD/YYYY') : false,
                placeholder: 'Enter start date',
                type: 'date'
            },
            {
                id: 10,
                value: 'banner_valid_till',
                label: 'End date',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "ba.banner_valid_till",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                defaultValue: (record && (checkIsAfter(record.banner_valid_from, record.banner_valid_till) || checkTwoDates(record.banner_valid_from, record.banner_valid_till, 31)) && setDateFormat(record.banner_valid_from)) || (record && record.banner_valid_till && setDateFormat(record.banner_valid_till)) || moment(user?.serverTime).add(30, 'days').format('MM/DD/YYYY'),
                minDate: record && setDateFormat(record?.banner_valid_from || user?.serverTime),
                maxDate: (record && record.banner_valid_from && moment(record.banner_valid_from).add(30, 'days').format('MM/DD/YYYY')) || moment(user?.serverTime).add(30, 'days').format('MM/DD/YYYY'),
                placeholder: 'Enter end date',
                type: 'date'
            },
            {
                id: 11,
                value: 'banner_notes',
                label: 'Banner notes',
                editRecord: true,
                viewRecord: false,
                required: false,
                rows: "1",
                placeholder: 'Enter notes',
                type: 'textarea'
            },
            {
                id: 12,
                value: 'lat_long',
                label: 'Location',
                editRecord: record?.global_display === 'Y' ? false : true,
                viewRecord: false,
                required: record?.global_display === 'Y' ? false : true,
                disabled: role === 'vendor' ? true : false,
                defaultValue: setLatLongFromVendor && typeof setLatLongFromVendor === 'function' && setLatLongFromVendor(record, metaData, role === 'vendor' ? sessionId : null),
                placeholder: 'Enter your location',
                type: 'location'
            },
            {
                id: 13,
                value: 'is_inactive',
                label: 'Active status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val, record) => {
                    const scheduled = record.banner_valid_from && moment(user?.serverTime).diff(moment(record.banner_valid_from))
                    const expired = record.banner_valid_till && moment(user?.serverTime).diff(moment(record.banner_valid_till))
                    return <div className={!val && scheduled && scheduled < 0 ? 'table_scheduled' : !val && expired && expired > 0 ? 'table_expired' : !val ? 'table_active' : 'table_inactive'}>{!val && scheduled && scheduled < 0 ? 'Scheduled' : !val && expired && expired > 0 ? 'Expired' : !val ? 'Active' : 'Inactive'}</div>
                },
                type: 'text'
            },
            {
                id: 14,
                value: 'createdAt',
                label: 'Created date',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "ba.createdAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 15,
                value: 'updatedAt',
                label: 'Last updated',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "ba.updatedAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 16,
                value: 'createdBy',
                label: 'Created by',
                editRecord: false,
                viewRecord: false,
                sort: false,
                type: 'text'
            },
            {
                id: 17,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            }
        ],
        children: [
            {
                name: 'create',
                title: 'Banner',
                path: '/banner/create',
                visible: true,
                component: CreateRecordPage
            },
            {
                name: 'edit',
                title: 'Banner',
                path: '/banner/:id',
                visible: true,
                component: EditRecordPage
            }
        ]
    },
    {
        schemaId: 4,
        name: 'posts',
        title: 'Posts',
        path: '/posts',
        visible: true,
        ...store('posts'),
        component: RecordsPage,
        icon: "fi-ss-box-open",
        exact: true,
        onSubmitValues: (submitValues) => {
            const values = Object.assign({}, submitValues)
            const lat_long = values.lat_long && values.lat_long.split(",")
            values.latitude = lat_long && lat_long[0]
            values.longitude = lat_long && lat_long[1]
            values.schedule_datetime_start = values.schedule_datetime_start && moment(values.schedule_datetime_start).format('YYYY-MM-DD')
            values.schedule_datetime_end = values.schedule_datetime_end && moment(values.schedule_datetime_end).format('YYYY-MM-DD')
            values.is_free_delivery = (values.new_price && parseFloat(values.new_price) === 0) && (values.old_price && parseFloat(values.old_price) === 0) ? false : values.is_free_delivery === 'Y'
            values.delivery_charge = (values.new_price && parseFloat(values.new_price) === 0) && (values.old_price && parseFloat(values.old_price) === 0) ? '' : values.delivery_charge
            values.global_display = (values.global_display === 'Y') ? true : false
            values.order = 1
            values.country_code = getCountryCodeFromPhoneNo(values.phone_no)
            values.phone_no = removeCountryCodeFromPhoneNo(values.phone_no)
            values.specifications = values.specifications && (values.specifications === '<p><br></p>' || values.specifications.replaceAll('&nbsp', '').replaceAll('<br>', '').replaceAll(' ', '').replaceAll('<p></p>', '') === '') ? '' : values.specifications
            values.product_title = values.product_title && values.product_title.replaceAll('"', '').replaceAll('', '').replaceAll(/''/g, '')
            values.product_description = values.product_description && values.product_description.replaceAll('"', '').replaceAll('', '').replaceAll(/''/g, '')
            values.detailed_description = values.detailed_description && values.detailed_description.replaceAll('"', '').replaceAll('', '').replaceAll(/''/g, '')
            values.specifications = values.specifications && values.specifications.replaceAll('"', '').replaceAll('', '').replaceAll(/''/g, '')
            values.free_world_wide_shipping = (values.free_world_wide_shipping === 'Y') ? true : false
            values.secure = (values.secure === 'Y') ? true : false
            values.cron_status = (values.cron_status === 'Y') ? true : false
            return values
        },
        config: {
            addBtnLabel: 'Add post',
            confirmDialogText: 'Do you want to delete this post?'
        },
        columns: (role, record, { user, ...metaData }, records, sessionId) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'product_gallery',
                label: 'Photos',
                editRecord: record?.product_video ? false : true,
                viewRecord: true,
                required: record?.product_video ? false : true,
                sort: true,
                sortValue: "po.product_url",
                fullWidth: record?.product_gallery ? true : false,
                uploadKey: 'public_url',
                uploadUrl: '/rest/posts/upload-files',
                placeholder: 'Drop files here or click to upload (Maximum 5 images allowed).',
                limit: 5,
                allowedFiles: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg': ['.svg'],
                    'image/webp': ['.webp'],
                    'image/gif': ['.gif'],
                    'image/x-ms-bmp': ['.bmp'],
                    'image/x-icon': ['.ico'],
                    'image/x-jng': ['.jng'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/avif': ['.avif']
                },
                decoratorView: ({ value, onChange }) => <Gallery items={value && value.split(',') && value.split(',').length > 0 && value.split(',').map(file => <img src={file} alt="file" />)}>
                    {(toggler) => (<div className="mb-3">
                        <div className="d-flex cursor-pointer align-items-center mb-2" onClick={() => onChange({ target: { value: '' } })}>
                            <i className="display-4 text-muted bx bxs-x-circle" style={{ fontSize: '20px', marginRight: '5px' }} /> <b>Delete All Photos</b>
                        </div>
                        <div className='d-flex w-100 overflow-auto'>
                            {value && value.split(',') && value.split(',').length > 0 && value.split(',').map((file, fileIndex) => (<div>
                                <img src={file} alt="file" key={fileIndex} width="230" height="230" style={{ borderRadius: '4px', marginRight: '10px', objectFit: 'contain' }} onClick={toggler} />
                                <div className="d-flex cursor-pointer mt-2 align-items-center" onClick={() => onChange({ target: { value: value && value.split(',').filter((l, i) => i !== fileIndex).join(',') } })}>
                                    <i className="display-4 text-muted bx bxs-x-circle" style={{ fontSize: '20px', marginRight: '5px' }} /> <b>Delete Photo</b>
                                </div>
                            </div>))}
                        </div>
                    </div>)}
                </Gallery>,
                formatter: (value, row, rowIndex) => value ?
                    <Gallery items={value && value.split(',') && value.split(',').length > 0 && value.split(',').map(file => <img src={file} alt="file" />)}>
                        {(toggler) => <img
                            src={value && value.split(',') && value.split(',').length > 0 && value.split(',')[0]}
                            width="50"
                            height="50"
                            style={{ borderRadius: '4px', marginRight: '10px', objectFit: 'contain' }} onClick={toggler} />}
                    </Gallery> : <div style={rowIndex === false ? null : { textAlign: 'center' }}>&mdash;</div>,
                type: 'multiUpload'
            },
            {
                id: 3,
                value: 'product_video',
                label: 'Video',
                editRecord: record?.product_gallery ? false : true,
                viewRecord: true,
                required: record?.product_gallery ? false : true,
                sort: true,
                sortValue: "po.product_video",
                fullWidth: record?.product_video ? true : false,
                uploadKey: 'public_url',
                uploadUrl: '/rest/posts/upload-files',
                placeholder: 'Drop files here or click to upload. Maximum: 30 seconds',
                allowedFiles: {
                    'video/3gpp': ['.3gpp', '.3gp'],
                    'video/mpeg': ['.mpeg', '.mpg'],
                    'video/x-ms-asf': ['.asx', '.asf'],
                    'video/quicktime': ['.mov'],
                    'video/ogg': ['.ogg'],
                    'video/x-ms-wmv': ['.wmv'],
                    'video/webm': ['.webm'],
                    'video/x-flv': ['.flv'],
                    'video/x-msvideo': ['.avi'],
                    'video/x-m4v': ['.m4v'],
                    'video/x-mng': ['.mng'],
                    'video/mp4': ['.mp4']
                },
                decoratorView: ({ value }) => <Gallery items={[<video src={value} style={{ width: '1289.7px', height: '680.115px' }} controls />]}>
                    {(toggler) => (<div className='gallery_video_view'>
                        <i className="display-4 text-muted bx bxs-movie-play" style={{ fontSize: '62px', marginRight: '5px', objectFit: 'contain' }} onClick={toggler} />
                    </div>)}
                </Gallery>,
                formatter: (value, row, rowIndex) => value ? <Gallery items={[<video src={value} style={{ width: '1289.7px', height: '680.115px' }} controls />]}>
                    {(toggler) => <div className='gallery_video_view' style={{ width: '50px', height: '50px' }}>
                        <i className="display-4 text-muted bx bxs-movie-play" style={{ fontSize: '34px', objectFit: 'contain' }} onClick={toggler} />
                    </div>}
                </Gallery> : <div style={rowIndex === false ? null : { textAlign: 'center' }}>&mdash;</div>,
                style: { marginRight: '15px', height: '230px' },
                type: 'upload'
            },
            {
                id: 4,
                value: 'global_display',
                label: 'Global display',
                editRecord: true,
                viewRecord: false,
                required: false,
                sort: false,
                viewForm: true,
                style: { marginTop: '32px' },
                placeholder: 'Select Global Display',
                formatter: (val) => val === 'Y' ? 'Available' : 'Not Available',
                type: 'checkbox'
            },
            {
                id: 5,
                value: 'product_url',
                label: 'Post URL',
                editRecord: true,
                viewRecord: false,
                required: record?.global_display === 'Y' ? true : false,
                disabled: record?.global_display === 'Y' ? false : true,
                sort: false,
                viewForm: true,
                validationUrl: `/rest/find-duplicates/posts`,
                getApiData: (e) => ({ url: e.target.value }),
                placeholder: 'Enter post url',
                type: 'customText'
            },
            {
                id: 6,
                value: 'vendor_id',
                label: 'Vendor',
                editRecord: role === 'vendor' ? false : true,
                viewRecord: false,
                required: true,
                sort: false,
                disabled: record.id,
                options: record && record.id ? "vendors" : "vendorsOptions",
                placeholder: 'Select vendor',
                formatter: (val, r, i, metaData) => metaData && metaData.vendors && metaData.vendors.length > 0 && metaData.vendors.find(_ => _.id.toString() === val.toString()) && metaData.vendors.find(_ => _.id.toString() === val.toString()).company_name,
                type: 'select'
            },
            {
                id: 7,
                value: 'company_name',
                label: 'Vendor',
                editRecord: false,
                viewRecord: role === 'vendor' ? false : true,
                required: false,
                sort: true,
                sortValue: "ve.company_name",
                placeholder: 'Enter company name',
                disableFormatter: true,
                formatter: (val, r) => r.company_name && r.company_name.length > 20 ? r.company_name.substring(0, 20) + ' ....' : r.company_name,
                type: 'input'
            },
            {
                id: 8,
                value: 'creatorName',
                label: 'Added by',
                editRecord: false,
                viewRecord: ['superAdmin', 'admin'].includes(role) ? true : false,
                required: false,
                sort: false,
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'input'
            },
            {
                id: 9,
                value: 'creatorRole',
                label: 'Creator role',
                editRecord: false,
                viewRecord: false,
                required: false,
                sort: false,
                type: 'input'
            },
            {
                id: 10,
                value: 'post_categories_id',
                label: 'Category',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: false,
                fullWidth: role === 'vendor' ? true : false,
                options: "postCategoriesOptions",
                placeholder: 'Select category',
                children: getKeywordFromCategories(record?.post_categories_id, metaData) ? `<i><b>Keywords:</b> ${getKeywordFromCategories(record?.post_categories_id, metaData)}</i>` : null,
                formatter: (val, r, i, metaData) => metaData && metaData.postCategories && metaData.postCategories.length > 0 && metaData.postCategories.find(_ => _.id.toString() === val.toString()) && metaData.postCategories.find(_ => _.id.toString() === val.toString()).category_name || 'Unknown Category',
                type: 'treeSelect'
            },
            {
                id: 11,
                value: 'post_categories_id',
                label: 'Keywords',
                editRecord: false,
                viewRecord: false,
                required: false,
                sort: false,
                viewForm: true,
                placeholder: 'Enter keyword',
                formatter: (val) => getKeywordFromCategories(val, metaData) || null,
                type: 'text'
            },
            {
                id: 12,
                value: 'product_title',
                label: 'Title',
                editRecord: true,
                viewRecord: true,
                required: true,
                maxLength: 200,
                sort: true,
                sortValue: "po.product_title",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter post title',
                type: 'text'
            },
            {
                id: 13,
                value: 'product_description',
                label: 'Short description',
                editRecord: true,
                viewRecord: false,
                required: false,
                sort: true,
                viewForm: true,
                sortValue: "po.product_description",
                rows: "1",
                maxLength: "200",
                placeholder: 'Maximum 200 characters',
                type: 'textarea'
            },
            {
                id: 14,
                value: 'old_price',
                label: 'Old price',
                editRecord: true,
                viewRecord: true,
                required: false,
                sort: true,
                sortValue: "po.old_price",
                formatter: (val) => val && Number.isInteger(val) ? val.toLocaleString('en-US') : val && val.toLocaleString('en-US', { minimumFractionDigits: 2 }) || '-',
                placeholder: 'Enter old price',
                type: 'number'
            },
            {
                id: 15,
                value: 'new_price',
                label: 'New price',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "po.new_price",
                formatter: (val) => val && Number.isInteger(val) ? val.toLocaleString('en-US') : val && val.toLocaleString('en-US', { minimumFractionDigits: 2 }) || '-',
                placeholder: 'Enter new price',
                type: 'number'
            },
            {
                id: 16,
                value: 'delivery_charge',
                label: 'Delivery charge',
                editRecord: true,
                viewRecord: true,
                required: (record?.new_price && parseFloat(record?.new_price) > 0 && (record?.is_free_delivery === 'N' || !record?.is_free_delivery)) ? true : false,
                sort: true,
                sortValue: "po.delivery_charge",
                disabled: ((record?.new_price === "0" && record?.old_price === "0") || (!record?.new_price && !record?.old_price) || record?.is_free_delivery === 'Y') ? true : false,
                formatter: (val) => val && Number.isInteger(val) ? val.toLocaleString('en-US') : val && val.toLocaleString('en-US', { minimumFractionDigits: 2 }) || '-',
                placeholder: 'Enter delivery charge',
                type: 'number'
            },
            {
                id: 17,
                value: 'is_free_delivery',
                label: 'Free delivery',
                editRecord: true,
                viewRecord: false,
                disabled: ((record?.new_price === "0" && record?.old_price === "0") || (!record?.new_price && !record?.old_price)) ? true : false,
                style: { marginTop: '32px' },
                sort: false,
                viewForm: true,
                formatter: (val) => val === 'Y' ? 'Available' : 'Not Available',
                type: 'checkbox'
            },
            {
                id: 18,
                value: 'schedule_datetime_start',
                label: 'Start date',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "po.schedule_datetime_start",
                placeholder: 'Enter schedule start date',
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                defaultValue: record && setDateFormat(record?.schedule_datetime_start || user?.serverTime),
                minDate: record && !record.id ? moment(user?.serverTime).format('MM/DD/YYYY') : false,
                type: 'date'
            },
            {
                id: 19,
                value: 'schedule_datetime_end',
                label: 'End date',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "po.schedule_datetime_end",
                placeholder: 'Enter schedule end date',
                disabled: false,
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                defaultValue: (record && (checkIsAfter(record.schedule_datetime_start, record.schedule_datetime_end) || checkTwoDates(record.schedule_datetime_start, record.schedule_datetime_end, 8)) && setDateFormat(record.schedule_datetime_start)) || (record && record.schedule_datetime_end && setDateFormat(record.schedule_datetime_end)) || moment(user?.serverTime).add(7, 'days').format('MM/DD/YYYY'),
                minDate: record && setDateFormat(record?.schedule_datetime_start || user?.serverTime),
                maxDate: (record && record.schedule_datetime_start && moment(record.schedule_datetime_start).add(7, 'days').format('MM/DD/YYYY')) || moment(user?.serverTime).add(7, 'days').format('MM/DD/YYYY'),
                type: 'date'
            },
            {
                id: 20,
                value: 'phone_no',
                label: 'Phone number',
                editRecord: true,
                viewRecord: true,
                required: false,
                sort: true,
                sortValue: "po.phone_no",
                placeholder: 'Enter phone number',
                formatter: (v, r) => r.country_code && r.phone_no ? `${r.country_code} ${removeCountryCodeFromPhoneNo(r.phone_no)}` : '',
                type: 'phone'
            },
            {
                id: 21,
                value: 'promo_code',
                label: 'Promo code',
                editRecord: true,
                viewRecord: true,
                required: false,
                sort: true,
                sortValue: "po.promo_code",
                placeholder: 'Enter promo Code',
                type: 'text'
            },
            {
                id: 22,
                value: 'assurance',
                label: 'Choose any one of these:',
                editRecord: true,
                viewRecord: false,
                required: false,
                defaultValue: record && !record.assurance ? 'warranty' : record && record.assurance || '',
                sort: false,
                options: [
                    {
                        label: 'Warranty',
                        value: 'warranty'
                    },
                    {
                        label: 'Guarantee',
                        value: 'guarantee'
                    }
                ],
                placeholder: 'Select any one',
                type: 'radio'
            },
            {
                id: 23,
                value: 'warranty',
                label: 'Warranty',
                editRecord: (record?.assurance === 'warranty') || (record && !record.assurance) ? true : false,
                viewRecord: false,
                required: false,
                sort: false,
                viewForm: (record?.assurance === 'warranty') || (record && !record.assurance) ? true : false,
                placeholder: 'Enter Warranty',
                type: 'number'
            },
            {
                id: 24,
                value: 'guarantee',
                label: 'Guarantee',
                editRecord: record?.assurance === 'guarantee' ? true : false,
                viewRecord: false,
                required: false,
                sort: false,
                viewForm: record?.assurance === 'guarantee' ? true : false,
                placeholder: 'Enter guarantee',
                type: 'number'
            },
            {
                id: 25,
                value: 'free_returns',
                label: 'Free returns',
                editRecord: true,
                viewRecord: false,
                required: false,
                sort: false,
                fullWidth: true,
                style: { width: "50%" },
                viewForm: true,
                placeholder: 'Enter Free Returns',
                type: 'number'
            },
            {
                id: 26,
                value: 'free_world_wide_shipping',
                label: 'International shipping',
                editRecord: true,
                viewRecord: false,
                required: false,
                sort: false,
                placeholder: 'Select free world wide shipping',
                viewForm: true,
                formatter: (val) => val === 'Y' ? 'available' : 'Not available',
                type: 'checkbox'
            },
            {
                id: 27,
                value: 'secure',
                label: 'Secure',
                editRecord: true,
                // below spoils the architecture
                editRecord: record?.global_display === 'Y' ? true : false,
                defaultValue: 'Y',
                viewRecord: false,
                required: false,
                sort: false,
                placeholder: 'Select secure',
                viewForm: true,
                formatter: (val) => val === 'Y' ? 'available' : 'Not available',
                type: 'checkbox'
            },
            // {
            //     id: 28,
            //     value: 'detailed_description',
            //     label: 'Detailed description',
            //     editRecord: true,
            //     viewRecord: false,
            //     required: false,
            //     sort: false,
            //     viewForm: true,
            //     rows: "12",
            //     style: { height: '301px' },
            //     placeholder: 'Enter detailed description',
            //     type: 'textarea'
            // },
            {
                id: 28,
                value: 'detailed_description',
                label: 'Detailed description',
                editRecord: true,
                viewRecord: false,
                required: false,
                rows: "6",
                placeholder: 'Enter detailed description',
                formatter: (val) => <div dangerouslySetInnerHTML={{ __html: val }} />,
                viewForm: true,
                type: 'textEditor'
            },
            {
                id: 29,
                value: 'specifications',
                label: 'Specifications',
                editRecord: true,
                viewRecord: false,
                required: false,
                rows: "6",
                placeholder: 'Enter specifications',
                formatter: (val) => <div dangerouslySetInnerHTML={{ __html: val }} />,
                viewForm: true,
                type: 'specificationTextEditor'
            },
            {
                id: 30,
                value: 'lat_long',
                label: 'Address',
                editRecord: record?.global_display === 'Y' ? false : true,
                viewRecord: false,
                required: record?.global_display === 'Y' ? false : true,
                disabled: role === 'vendor' ? true : false,
                viewForm: record?.global_display === 'Y' ? false : true,
                formatter: (val) => <ViewLocation value={val} />,
                defaultValue: (record?.id && record?.lat_long) || (setLatLongFromVendor && typeof setLatLongFromVendor === 'function' && setLatLongFromVendor(record, metaData, role === 'vendor' ? sessionId : null)),
                placeholder: 'Enter address',
                type: 'location'
            },
            {
                id: 31,
                value: 'is_inactive',
                label: 'Active status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val, record) => {
                    const scheduled = record.schedule_datetime_start && moment(user?.serverTime).diff(moment(record.schedule_datetime_start))
                    const expired = record.schedule_datetime_end && moment(user?.serverTime).diff(moment(record.schedule_datetime_end))
                    return <div className={!val && scheduled && scheduled < 0 ? 'table_scheduled' : !val && expired && expired > 0 ? 'table_expired' : !val ? 'table_active' : 'table_inactive'}>{!val && scheduled && scheduled < 0 ? 'Scheduled' : !val && expired && expired > 0 ? 'Expired' : !val ? 'Active' : 'Inactive'}</div>
                },
                type: 'text'
            },
            {
                id: 32,
                value: 'keyword',
                label: 'Keyword',
                editRecord: false,
                viewRecord: false,
                sort: false,
                defaultValue: getKeywordFromCategories(record?.post_categories_id, metaData) || null,
                type: 'text'
            },
            {
                id: 33,
                value: 'createdAt',
                label: 'Created date',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "po.createdAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 34,
                value: 'updatedAt',
                label: 'Last updated',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "po.updatedAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 35,
                value: 'createdBy',
                label: 'Created by',
                editRecord: false,
                viewRecord: false,
                sort: false,
                type: 'text'
            },
            {
                id: 36,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            },
            {
                id: 37,
                value: 'cron_status',
                label: 'Disable CRON',
                editRecord: record?.global_display === 'Y' && (role !== 'vendor') ? true : false,
                viewRecord: false,
                required: false,
                sort: false,
                placeholder: 'Select Cron',
                viewForm: (role !== 'vendor') ? true : false,
                formatter: (val) => val === 'Y' ? 'available' : 'Not available',
                type: 'checkbox'
            },
        ],
        children: [
            {
                name: 'create                                               ',
                title: 'Posts',
                path: '/posts/create',
                visible: true,
                component: CreateRecordPage
            },
            {
                name: 'edit',
                title: 'Posts',
                path: '/posts/:id',
                visible: true,
                component: EditRecordPage
            }
        ]
    },
    {
        schemaId: 5,
        name: 'user',
        title: 'Users',
        path: '/user',
        visible: true,
        ...store('user'),
        component: RecordsPage,
        icon: "fi-rr-users",
        exact: true,
        config: {
            addBtnLabel: 'Add User',
            confirmDialogText: 'Do you want to delete this user?'
        },
        onSubmitValues: (submitValues) => {
            const values = Object.assign({}, submitValues)
            values.country_code = getCountryCodeFromPhoneNo(values.phone)
            values.phone = removeCountryCodeFromPhoneNo(values.phone)

            return values
        },
        columns: (role, record) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'profile_image',
                label: 'Profile photo',
                editRecord: true,
                viewRecord: true,
                required: false,
                sort: true,
                sortValue: "profile_image",
                fullWidth: true,
                uploadKey: 'public_url',
                uploadUrl: '/rest/user/profile-upload',
                className: 'profile_photo_gallery',
                style: { width: '180px', height: '180px', minHeight: '180px' },
                placeholderStyle: { fontSize: '12px' },
                uploadPercentageStyle: { fontSize: '12px' },
                allowedFiles: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg': ['.svg'],
                    'image/webp': ['.webp'],
                    'image/gif': ['.gif'],
                    'image/x-ms-bmp': ['.bmp'],
                    'image/x-icon': ['.ico'],
                    'image/x-jng': ['.jng'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/avif': ['.avif']
                },
                formatter: (imageUrl) => <Gallery items={[<img src={imageUrl || user1} alt="profile" onError={(e) => e.target.src = user1} />]}>{(toggler) => <img src={imageUrl || user1} alt="profile photo" onError={(e) => e.target.src = user1} className="profile_photo_gallery" onClick={toggler} />}</Gallery>,
                decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="profile" />]}>{(toggler) => <img src={value} alt="profile photo" className="profile_photo_gallery" style={{ width: '180px', height: '180px' }} onClick={toggler} />}</Gallery>,
                type: 'upload'
            },
            {
                id: 3,
                value: 'name',
                label: 'Name',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "name",
                placeholder: 'Enter name',
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'text'
            },
            {
                id: 4,
                value: 'profile_name',
                label: 'Profile name',
                editRecord: false,
                viewRecord: false,
                required: false,
                sort: false,
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter profile name',
                type: 'text'
            },
            {
                id: 5,
                value: 'email',
                label: 'Email',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "email",
                placeholder: 'Enter email',
                type: 'text'
            },
            {
                id: 6,
                value: 'phone',
                label: 'Phone',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "phone",
                placeholder: 'Enter phone',
                allowedCountries: ['ae'],
                countryCodeEditable: true,
                disableDropdown: true,
                formatter: (v, r) => r.country_code && r.phone ? `${r.country_code} ${removeCountryCodeFromPhoneNo(r.phone)}` : '',
                type: 'phone'
            },
            {
                id: 7,
                value: 'password',
                label: 'Password',
                editRecord: true,
                viewRecord: false,
                required: true,
                disabled: record && record.id ? true : false,
                sort: true,
                placeholder: 'Enter password',
                type: 'password'
            },
            {
                id: 8,
                value: 'notes',
                label: 'User notes',
                editRecord: true,
                viewRecord: false,
                required: false,
                viewForm: true,
                rows: "1",
                placeholder: 'Enter notes',
                type: 'textarea'
            },
            {
                id: 9,
                value: 'is_inactive',
                label: 'Active status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val) => <div className={!val ? 'table_active' : 'table_inactive'}>{!val ? 'Active' : 'Inactive'}</div>,
                type: 'text'
            },
            {
                id: 10,
                value: 'creatorName',
                label: 'Added by',
                editRecord: false,
                viewRecord: true,
                sort: false,
                type: 'text'
            },
            {
                id: 11,
                value: 'createdAt',
                label: 'Created date',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "createdAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 12,
                value: 'updatedAt',
                label: 'Last updated',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "updatedAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 13,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            }
        ],
        children: [
            {
                name: 'create',
                title: 'User',
                path: '/user/create',
                visible: true,
                component: CreateRecordPage
            },
            {
                name: 'edit',
                title: 'User',
                path: '/user/:id',
                visible: true,
                component: EditRecordPage
            }
        ]
    },
    {
        schemaId: 6,
        name: 'accounts',
        title: 'Accounts',
        path: '/accounts',
        visible: true,
        ...store('accounts'),
        component: RecordsPage,
        icon: "fi-rr-user",
        exact: true,
        onSubmitValues: (submitValues) => {
            const values = Object.assign({}, submitValues)
            values.country_code = getCountryCodeFromPhoneNo(values.phone)
            values.phone = removeCountryCodeFromPhoneNo(values.phone)
            return values
        },
        config: {
            addBtnLabel: 'Add account',
            confirmDialogText: 'Do you want to delete this account?'
        },
        columns: (role, record) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'profile_image',
                label: 'Profile photo',
                editRecord: true,
                viewRecord: true,
                required: false,
                sort: true,
                sortValue: 'profile_image',
                fullWidth: true,
                uploadKey: 'public_url',
                uploadUrl: '/rest/accounts/profile-upload',
                className: 'profile_photo_gallery',
                style: { width: '180px', height: '180px', minHeight: '180px' },
                placeholderStyle: { fontSize: '12px' },
                uploadPercentageStyle: { fontSize: '12px' },
                allowedFiles: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg': ['.svg'],
                    'image/webp': ['.webp'],
                    'image/gif': ['.gif'],
                    'image/x-ms-bmp': ['.bmp'],
                    'image/x-icon': ['.ico'],
                    'image/x-jng': ['.jng'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/avif': ['.avif']
                },
                formatter: (imageUrl) => <Gallery items={[<img src={imageUrl || user1} alt="profile" onError={(e) => e.target.src = user1} />]}>{(toggler) => <img src={imageUrl || user1} onError={(e) => e.target.src = user1} alt="profile photo" className='profile_photo_gallery' onClick={toggler} />}</Gallery>,
                decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="profile" />]}>{(toggler) => <img src={value} alt="profile photo" className='profile_photo_gallery' style={{ width: '180px', height: '180px' }} onClick={toggler} />}</Gallery>,
                type: 'upload'
            },
            {
                id: 3,
                value: 'name',
                label: 'Name',
                editRecord: true,
                viewRecord: true,
                required: true,
                disabled: false,
                sort: true,
                sortValue: "name",
                placeholder: 'Enter name',
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'text'
            },
            {
                id: 4,
                value: 'profile_name',
                label: 'Profile name',
                editRecord: true,
                viewRecord: true,
                required: false,
                sort: true,
                sortValue: "profile_name",
                placeholder: 'Enter profile name',
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'text'
            },
            {
                id: 5,
                value: 'role',
                label: 'Role',
                editRecord: true,
                viewRecord: true,
                required: true,
                disabled: record && record.id ? true : false,
                sort: true,
                sortValue: "role",
                placeholder: 'Select role',
                options: [
                    {
                        label: 'Staff',
                        value: 'staff'
                    },
                    {
                        label: 'Admin',
                        value: 'admin'
                    }
                ],
                type: 'select'
            },
            {
                id: 6,
                value: 'email',
                label: 'Email',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "email",
                placeholder: 'Enter email',
                type: 'text'
            },
            {
                id: 7,
                value: 'phone',
                label: 'Phone',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "phone",
                placeholder: 'Enter phone',
                formatter: (v, r) => r.country_code && r.phone ? `${r.country_code} ${removeCountryCodeFromPhoneNo(r.phone)}` : '',
                type: 'phone'
            },
            {
                id: 8,
                value: 'access',
                label: 'Access',
                editRecord: true,
                viewRecord: false,
                required: true,
                sort: false,
                placeholder: 'Select Access',
                options: record.role === 'staff' ? [
                    {
                        label: 'Select All',
                        value: 'All'
                    },
                    {
                        label: 'Categories',
                        value: 'post-categories'
                    },
                    {
                        label: 'Banner',
                        value: 'banner'
                    },
                    {
                        label: 'Posts',
                        value: 'posts'
                    },
                    {
                        label: 'Settings',
                        value: 'settings'
                    }
                ] :
                    [
                        {
                            label: 'Select All',
                            value: 'All'
                        },
                        {
                            label: 'Vendor',
                            value: 'vendor'
                        },
                        {
                            label: 'Categories',
                            value: 'post-categories'
                        },
                        {
                            label: 'Banner',
                            value: 'banner'
                        },
                        {
                            label: 'Posts',
                            value: 'posts'
                        },
                        {
                            label: 'User',
                            value: 'user'
                        },
                        {
                            label: 'Accounts',
                            value: 'accounts'
                        },
                        {
                            label: 'Notifications',
                            value: 'notification'
                        },
                        {
                            label: 'Data scraping',
                            value: 'scraping'
                        },
                        {
                            label: 'Comments',
                            value: 'comments'
                        },
                        {
                            label: 'Settings',
                            value: 'settings'
                        },
                    ],
                type: 'multiSelect'
            },
            {
                id: 9,
                value: 'vendors',
                label: 'Vendors',
                editRecord: (record && record.role && record.role === 'staff') ? true : false,
                viewRecord: false,
                required: false,
                sort: false,
                placeholder: 'Select vendors',
                options: 'vendors',
                type: 'multiSelect'
            },
            {
                id: 10,
                value: 'password',
                label: 'Password',
                editRecord: true,
                viewRecord: false,
                required: true,
                disabled: record && record.id ? true : false,
                sort: false,
                placeholder: 'Enter password',
                type: 'password'
            },
            {
                id: 11,
                value: 'notes',
                label: 'Account notes',
                editRecord: true,
                viewRecord: false,
                required: false,
                viewForm: true,
                rows: "1",
                placeholder: 'Enter notes',
                type: 'textarea'
            },
            {
                id: 12,
                value: 'is_inactive',
                label: 'Active status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val) => <div className={!val ? 'table_active' : 'table_inactive'}>{!val ? 'Active' : 'Inactive'}</div>,
                type: 'text'
            },
            {
                id: 13,
                value: 'creatorName',
                label: 'Added by',
                editRecord: false,
                viewRecord: true,
                sort: false,
                type: 'text'
            },
            {
                id: 14,
                value: 'createdAt',
                label: 'Created date',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "createdAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 15,
                value: 'updatedAt',
                label: 'Last updated',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "updatedAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 16,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            }
        ],
        children: [
            {
                name: 'create',
                title: 'Accounts',
                path: '/accounts/create',
                visible: true,
                component: CreateRecordPage
            },
            {
                name: 'edit',
                title: 'Accounts',
                path: '/accounts/:id',
                visible: true,
                component: EditRecordPage
            }
        ]
    },
    {
        schemaId: 7,
        name: 'notification',
        title: 'Notifications',
        path: '/notification',
        visible: true,
        ...store('notification'),
        component: RecordsPage,
        icon: "fi-rr-bell",
        exact: true,
        config: {
            addBtnLabel: 'Add notification',
            confirmDialogText: 'Do you want to delete this notification?'
        },
        onSubmitValues: (submitValues) => {
            const values = Object.assign({}, submitValues)
            values.notifications_valid_from = values.notifications_valid_from && moment(values.notifications_valid_from).format('YYYY-MM-DD')
            values.notifications_valid_till = values.notifications_valid_till && moment(values.notifications_valid_till).format('YYYY-MM-DD')

            return values
        },
        columns: (role, record, { user }) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'public_url',
                label: 'Notification photo',
                editRecord: true,
                viewRecord: true,
                required: true,
                fullWidth: true,
                sort: true,
                sortValue: "public_url",
                uploadKey: 'public_url',
                uploadUrl: '/rest/notifications/upload-files',
                allowedFiles: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg': ['.svg'],
                    'image/webp': ['.webp'],
                    'image/gif': ['.gif'],
                    'image/x-ms-bmp': ['.bmp'],
                    'image/x-icon': ['.ico'],
                    'image/x-jng': ['.jng'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/avif': ['.avif']
                },
                formatter: (imageUrl) => <Gallery items={[<img src={imageUrl} alt="notification" onError={(e) => e.target.src = "https://placehold.co/600x400?text=No+Data"} />]}>{(toggler) => <img src={imageUrl} alt="notification photo" onError={(e) => e.target.src = "https://placehold.co/600x400?text=No+Data"} height="50" style={{ objectFit: 'contain' }} onClick={toggler} />}</Gallery>,
                decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="notification" />]}>{(toggler) => <img src={value} alt="notification photo" style={{ maxWidth: '100%', height: '330px', objectFit: 'contain' }} onClick={toggler} />}</Gallery>,
                placeholder: 'Upload Notification Photo',
                type: 'upload'
            },
            {
                id: 3,
                value: 'type',
                label: 'Type',
                editRecord: true,
                viewRecord: true,
                required: true,
                fullWidth: true,
                sort: true,
                sortValue: "type",
                options: [
                    {
                        label: 'Vendors',
                        value: 'vendors'
                    },
                    {
                        label: 'Users',
                        value: 'users'
                    }
                ],
                placeholder: 'Select Type',
                type: 'select'
            },
            {
                id: 4,
                value: 'notifications_valid_from',
                label: 'Start date',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "notifications_valid_from",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                defaultValue: record && setDateFormat(record?.notifications_valid_from || user?.serverTime),
                minDate: record && !record.id ? moment(user?.serverTime).format('MM/DD/YYYY') : false,
                placeholder: 'Enter start date',
                type: 'date'
            },
            {
                id: 5,
                value: 'notifications_valid_till',
                label: 'End date',
                editRecord: true,
                viewRecord: true,
                required: true,
                sort: true,
                sortValue: "notifications_valid_till",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                defaultValue: (record && checkIsAfter(record.notifications_valid_from, record.notifications_valid_till) && setDateFormat(record.notifications_valid_from || user?.serverTime)) || record && setDateFormat(record.notifications_valid_till || user?.serverTime),
                minDate: record && setDateFormat(record?.notifications_valid_from || user?.serverTime),
                placeholder: 'Enter end date',
                type: 'date'
            },
            {
                id: 6,
                value: 'notes',
                label: 'Notification notes',
                editRecord: true,
                viewRecord: false,
                required: false,
                viewForm: true,
                rows: "1",
                placeholder: 'Enter notes',
                type: 'textarea'
            },
            {
                id: 7,
                value: 'is_inactive',
                label: 'Active status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val, record) => {
                    const scheduled = record.notifications_valid_from && moment(user?.serverTime).diff(moment(record.notifications_valid_from))
                    const expired = record.notifications_valid_till && moment(user?.serverTime).diff(moment(record.notifications_valid_till))
                    return <div className={!val && scheduled && scheduled < 0 ? 'table_scheduled' : !val && expired && expired > 0 ? 'table_expired' : !val ? 'table_active' : 'table_inactive'}>{!val && scheduled && scheduled < 0 ? 'Scheduled' : !val && expired && expired > 0 ? 'Expired' : !val ? 'Active' : 'Inactive'}</div>
                },
                type: 'text'
            },
            {
                id: 8,
                value: 'creatorName',
                label: 'Added by',
                editRecord: false,
                viewRecord: true,
                sort: false,
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'text'
            },
            {
                id: 9,
                value: 'createdAt',
                label: 'Created date',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "createdAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 10,
                value: 'updatedAt',
                label: 'Last updated',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "updatedAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 11,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            }
        ],
        children: [
            {
                name: 'create',
                title: 'Notification',
                path: '/notification/create',
                visible: true,
                component: CreateRecordPage
            },
            {
                name: 'edit',
                title: 'Notification',
                path: '/notification/:id',
                visible: true,
                component: EditRecordPage
            }
        ]
    },
    {
        schemaId: 8,
        title: 'Data scraping',
        path: '/scraping',
        visible: true,
        ...store('scraping'),
        component: RecordsPage,
        icon: "fi fi-rs-customize-computer",
        name: 'scraping',
        exact: true,
        config: {
            addBtnLabel: 'Add site identifiers',
            confirmDialogText: 'Do you want to delete this site identifiers?'
        },
        columns: (role, record, { user }) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'site_name',
                label: 'Site name',
                editRecord: true,
                viewRecord: true,
                required: true,
                maxLength: 200,
                sort: true,
                sortValue: "site_name",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter site name',
                type: 'text'
            },
            {
                id: 3,
                value: 'site_url',
                label: 'Site URL',
                editRecord: true,
                viewRecord: true,
                required: true,
                disabled: false,
                sort: false,
                viewForm: true,
                validationUrl: `/rest/find/site`,
                getApiData: (e) => ({ url: e.target.value }),
                placeholder: 'Enter site url',
                type: 'customText'
            },
            {
                id: 4,
                value: 'title_identifier',
                label: 'Title identifier',
                editRecord: true,
                viewRecord: true,
                required: true,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter title identifier',
                type: 'text'
            },
            {
                id: 5,
                value: 'gallery_identifier',
                label: 'Gallery identifier',
                editRecord: true,
                viewRecord: true,
                required: true,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter gallery identifier',
                type: 'text'
            },
            {
                id: 6,
                value: 'old_price_identifier',
                label: 'Old price identifier',
                editRecord: true,
                viewRecord: true,
                required: true,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter old price identifier',
                type: 'text'
            },
            {
                id: 7,
                value: 'new_price_identifier',
                label: 'New price identifier',
                editRecord: true,
                viewRecord: true,
                required: true,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter new price identifier',
                type: 'text'
            },
            {
                id: 8,
                value: 'detailed_description_identifier',
                label: 'Description identifier',
                editRecord: true,
                viewRecord: true,
                required: true,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter description identifier',
                type: 'text'
            },
            {
                id: 9,
                value: 'delivery_details_identifier',
                label: 'Delivery details identifier',
                editRecord: true,
                viewRecord: true,
                required: false,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter delivery details identifier',
                type: 'text'
            },
            {
                id: 10,
                value: 'promo_code_identifier',
                label: 'Promo code identifier',
                editRecord: true,
                viewRecord: true,
                required: false,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter promo code identifier',
                type: 'text'
            },
            {
                id: 11,
                value: 'returns_details_identifier',
                label: 'Returns details identifier',
                editRecord: true,
                viewRecord: true,
                required: false,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter return details identifier',
                type: 'text'
            },
            {
                id: 12,
                value: 'warranty_guarantee_details_identifier',
                label: 'Warranty/Guarantee details identifier',
                editRecord: true,
                viewRecord: true,
                required: false,
                maxLength: 200,
                sort: false,
                sortValue: "po.title_identifier",
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                placeholder: 'Enter warranty/guarantee details identifier',
                type: 'text'
            },
            {
                id: 13,
                value: 'is_inactive',
                label: 'Active status',
                editRecord: false,
                viewRecord: true,
                sort: false,
                formatter: (val) => <div className={!val ? 'table_active' : 'table_inactive'}>{!val ? 'Active' : 'Inactive'}</div>,
                type: 'text'
            },
            {
                id: 14,
                value: 'creatorName',
                label: 'Added by',
                editRecord: false,
                viewRecord: true,
                sort: false,
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'text'
            },
            {
                id: 15,
                value: 'createdAt',
                label: 'Created date',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "createdAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 16,
                value: 'updatedAt',
                label: 'Last updated',
                editRecord: false,
                viewRecord: true,
                sort: true,
                sortValue: "updatedAt",
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 17,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            }
        ],
        children: [
            {
                name: 'create',
                title: 'Data scraping',
                path: '/scraping/create',
                visible: true,
                component: CreateRecordPage
            },
            {
                name: 'edit',
                title: 'Data scraping',
                path: '/scraping/:id',
                visible: true,
                component: EditRecordPage
            }
        ],
        onSubmitValues: (submitValues) => {
            const values = Object.assign({}, submitValues)
            values.notifications_valid_from = values.notifications_valid_from && moment(values.notifications_valid_from).format('YYYY-MM-DD')
            values.notifications_valid_till = values.notifications_valid_till && moment(values.notifications_valid_till).format('YYYY-MM-DD')

            return values
        },
    },
    {
        schemaId: 9,
        name: 'settings',
        title: 'Settings',
        path: '/settings',
        visible: true,
        component: SettingsPage,
        icon: "fi-rr-settings",
        exact: true,
        ...store('settings'),
        profileColumns: (role, record, { user }) => {
            return role === 'vendor' ? [
                {
                    id: 1,
                    value: 'id',
                    label: 'ID',
                    editRecord: false,
                    viewRecord: false,
                    viewMode: false,
                    type: 'text'
                },
                {
                    id: 2,
                    value: 'profile_image',
                    label: 'Company logo',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    sort: false,
                    fullWidth: !record.license_expired_in_10_days,
                    uploadKey: 'public_url',
                    uploadUrl: '/rest/vendor/profile-upload',
                    className: 'profile_photo_gallery',
                    style: { width: '120px', height: '120px', minHeight: '120px', display: 'flex', alignItems: 'center' },
                    placeholderStyle: { fontSize: '12px' },
                    uploadPercentageStyle: { fontSize: '12px' },
                    allowedFiles: {
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpg', '.jpeg'],
                        'image/svg': ['.svg'],
                        'image/webp': ['.webp'],
                        'image/gif': ['.gif'],
                        'image/x-ms-bmp': ['.bmp'],
                        'image/x-icon': ['.ico'],
                        'image/x-jng': ['.jng'],
                        'image/tiff': ['.tif', '.tiff'],
                        'image/avif': ['.avif']
                    },
                    placeholder: 'Upload Photo',
                    formatter: (imageUrl) => <Gallery items={[<img src={imageUrl || user1} alt="company" />]}>{(toggler) => <img src={imageUrl || default_company_logo} alt="company photo" className='profile_photo_gallery' onClick={toggler} />}</Gallery>,
                    decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="company" />]}>{(toggler) => <img src={value} alt="company photo" className='profile_photo_gallery' style={{ width: '120px', height: '120px' }} onClick={toggler} />}</Gallery>,
                    type: 'upload'
                },
                {
                    id: 3,
                    value: 'license_document',
                    label: 'Trade license',
                    editRecord: record.license_expired_in_10_days,
                    viewRecord: false,
                    required: false,
                    sort: false,
                    viewForm: true,
                    uploadKey: 'public_url',
                    uploadUrl: '/rest/vendor/license-upload',
                    placeholderStyle: { fontSize: '12px' },
                    uploadPercentageStyle: { fontSize: '12px' },
                    placeholder: 'Drop pdf format file here or click to upload your renewed trade license copy to avoid service interruption.',
                    allowedFiles: {
                        'application/pdf': ['.pdf']
                    },
                    formatter: (value) => <img src={pdf_image} alt="pdf" width="50" height="50" style={{ cursor: 'pointer' }} onClick={() => window.open(value, '_blank')} />,
                    decoratorView: ({ value }) => <i className={`mdi mdi-file-pdf-box d-block`} style={{ fontSize: "95px", cursor: 'pointer' }} onClick={() => window.open(value, '_blank')}></i>,
                    type: 'upload'
                },
                {
                    id: 4,
                    value: 'company_name',
                    label: 'Company name',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    disabled: true,
                    sort: false,
                    placeholder: 'Enter company name',
                    type: 'text'
                },
                {
                    id: 5,
                    value: 'company_email',
                    label: 'Company Email (Verification required)',
                    editRecord: true,
                    viewRecord: false,
                    required: true,
                    sort: false,
                    placeholder: 'Enter company email',
                    type: 'text'
                },
                {
                    id: 6,
                    value: 'company_phone_no',
                    label: 'Company Phone Number (Verification required)',
                    editRecord: true,
                    viewRecord: false,
                    required: true,
                    sort: false,
                    placeholder: 'Enter company phone number',
                    type: 'phone'
                },
                {
                    id: 7,
                    value: 'contact_person',
                    label: 'Contact Person',
                    editRecord: true,
                    viewRecord: false,
                    required: true,
                    sort: false,
                    placeholder: 'Enter contact person',
                    type: 'text'
                },
                {
                    id: 8,
                    value: 'license_number',
                    label: 'Trade license number',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    disabled: true,
                    sort: false,
                    placeholder: 'Enter license number',
                    type: 'text'
                },
                {
                    id: 9,
                    value: 'license_expiry_date',
                    label: 'License Expiry Date',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    disabled: true,
                    sort: false,
                    minDate: record && !record.id ? moment(user?.serverTime).format('MM/DD/YYYY') : false,
                    placeholder: 'Enter license expiry date',
                    type: 'date'
                },
                {
                    id: 10,
                    value: 'area',
                    label: 'Area',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    disabled: true,
                    sort: false,
                    placeholder: 'Enter area',
                    type: 'text'
                },
                {
                    id: 11,
                    value: 'city',
                    label: 'City',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    disabled: true,
                    sort: false,
                    placeholder: 'Enter city',
                    type: 'text'
                },
                {
                    id: 12,
                    value: 'state',
                    label: 'State',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    disabled: true,
                    sort: false,
                    placeholder: 'Enter state',
                    type: 'text'
                },
                {
                    id: 13,
                    value: 'country',
                    label: 'Country',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    disabled: true,
                    sort: false,
                    options: countriesList,
                    placeholder: 'Select country',
                    type: 'select'
                },
                {
                    id: 14,
                    value: 'post_box_no',
                    label: 'Post Box No.',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    disabled: true,
                    sort: false,
                    placeholder: 'Enter post box no.',
                    type: 'number'
                },
                {
                    id: 15,
                    value: 'lat_long',
                    label: 'Address',
                    editRecord: true,
                    viewRecord: false,
                    disabled: true,
                    required: true,
                    placeholder: 'Enter address',
                    type: 'location'
                }
            ] : [
                {
                    id: 1,
                    value: 'id',
                    label: 'ID',
                    editRecord: false,
                    viewRecord: false,
                    viewMode: false,
                    type: 'text'
                },
                {
                    id: 2,
                    value: 'profile_image',
                    label: 'Profile photo',
                    editRecord: true,
                    viewRecord: true,
                    required: false,
                    sort: false,
                    fullWidth: true,
                    uploadKey: 'public_url',
                    uploadUrl: '/rest/accounts/profile-upload',
                    className: 'profile_photo_gallery',
                    style: { width: '120px', height: '120px', minHeight: '120px', display: 'flex', alignItems: 'center' },
                    placeholderStyle: { fontSize: '12px' },
                    uploadPercentageStyle: { fontSize: '12px' },
                    allowedFiles: {
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpg', '.jpeg'],
                        'image/svg': ['.svg'],
                        'image/webp': ['.webp'],
                        'image/gif': ['.gif'],
                        'image/x-ms-bmp': ['.bmp'],
                        'image/x-icon': ['.ico'],
                        'image/x-jng': ['.jng'],
                        'image/tiff': ['.tif', '.tiff'],
                        'image/avif': ['.avif']
                    },
                    placeholder: 'Upload Photo',
                    decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="profile" />]}>{(toggler) => <img src={value} alt="profile photo" className='profile_photo_gallery' style={{ width: '120px', height: '120px', objectFit: "contain", padding: "4px" }} onClick={toggler} />}</Gallery>,
                    type: 'upload'
                },
                {
                    id: 3,
                    value: 'name',
                    label: 'Name',
                    editRecord: true,
                    viewRecord: false,
                    required: true,
                    disabled: role === 'superAdmin' ? false : true,
                    placeholder: 'Enter name',
                    type: 'text'
                },
                {
                    id: 4,
                    value: 'profile_name',
                    label: 'Profile name',
                    editRecord: true,
                    viewRecord: false,
                    required: false,
                    placeholder: 'Enter profile name',
                    type: 'text'
                },
                {
                    id: 5,
                    value: 'email',
                    label: 'Email (Verification required)',
                    editRecord: true,
                    viewRecord: false,
                    required: true,
                    placeholder: 'Enter email',
                    type: 'text'
                },
                {
                    id: 6,
                    value: 'phone',
                    label: 'Phone (Verification required)',
                    editRecord: true,
                    viewRecord: false,
                    required: true,
                    placeholder: 'Enter phone',
                    type: 'phone'
                }
            ]
        },
        changePasswordColumns: () => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'password',
                label: 'Enter your old Password',
                editRecord: true,
                viewRecord: false,
                required: true,
                fullWidth: true,
                placeholder: 'Enter password',
                type: 'password'
            },
            {
                id: 3,
                value: 'new_password',
                label: 'Enter your new Password',
                editRecord: true,
                viewRecord: false,
                required: true,
                fullWidth: true,
                placeholder: 'Enter new password',
                type: 'password'
            },
            {
                id: 4,
                value: 'confirm_password',
                label: 'Enter your confirm Password',
                editRecord: true,
                viewRecord: false,
                required: true,
                fullWidth: true,
                placeholder: 'Enter confirm password',
                type: 'password'
            }
        ],
        twoFactorAuthentication: () => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'two_factor_status',
                label: 'Enable two factor authentication',
                editRecord: true,
                viewRecord: false,
                viewMode: false,
                type: 'switch'
            }
        ],
        changeLogoAndTheme: () => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'profile_image',
                label: 'Profile photo',
                editRecord: true,
                viewRecord: true,
                required: false,
                sort: false,
                fullWidth: true,
                uploadKey: 'public_url',
                uploadUrl: '/rest/accounts/profile-upload',
                className: 'profile_photo_gallery',
                style: { width: '120px', height: '120px', minHeight: '120px', display: 'flex', alignItems: 'center' },
                placeholderStyle: { fontSize: '12px' },
                uploadPercentageStyle: { fontSize: '12px' },
                allowedFiles: {
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg': ['.svg'],
                    'image/webp': ['.webp'],
                    'image/gif': ['.gif'],
                    'image/x-ms-bmp': ['.bmp'],
                    'image/x-icon': ['.ico'],
                    'image/x-jng': ['.jng'],
                    'image/tiff': ['.tif', '.tiff'],
                    'image/avif': ['.avif']
                },
                placeholder: 'Upload Photo',
                decoratorView: ({ value }) => <Gallery items={[<img src={value} alt="profile" />]}>{(toggler) => <img src={value} alt="profile photo" className='profile_photo_gallery' style={{ width: '120px', height: '120px' }} onClick={toggler} />}</Gallery>,
                type: 'upload'
            },
        ]
    },
    {
        schemaId: 10,
        name: 'comments',
        title: 'Comments',
        path: '/comments',
        visible: true,
        ...store('comments'),
        hideMenu: true,
        component: RecordsPage,
        icon: "bx-bell",
        exact: true,
        config: {
            addBtnLabel: 'Add Comment',
            confirmDialogText: 'Do you want to delete this comment?'
        },
        columns: (role, record, { user }) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'rowId',
                label: 'S No',
                viewRecord: true,
                viewMode: false,
                type: 'text'
            },
            {
                id: 3,
                value: 'vendor_id',
                label: 'Vendor ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 4,
                value: 'posts_id',
                label: 'Posts ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 5,
                value: 'user_id',
                label: 'User ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 6,
                value: 'comments',
                label: 'Comment',
                editRecord: true,
                viewRecord: true,
                viewMode: false,
                fullWidth: true,
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                sort: false,
                type: 'textarea'
            },
            {
                id: 7,
                value: 'reply',
                label: 'Reply',
                editRecord: true,
                viewRecord: true,
                viewMode: false,
                fullWidth: true,
                disableFormatter: true,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                sort: false,
                type: 'textarea'
            },
            {
                id: 8,
                value: 'rely_available',
                label: 'Reply available',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 9,
                value: 'is_deleted',
                label: 'Deleted',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 10,
                value: 'creator_name',
                label: 'Added by',
                editRecord: false,
                viewRecord: true,
                viewMode: false,
                type: 'text'
            },
            {
                id: 11,
                value: 'createdBy',
                label: 'Created by',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 12,
                value: 'creatorRole',
                label: 'Created role',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 13,
                value: 'createdAt',
                label: 'Created date',
                editRecord: false,
                viewRecord: true,
                viewMode: false,
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 14,
                value: 'updatedAt',
                label: 'Last updated',
                editRecord: false,
                viewRecord: true,
                viewMode: false,
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 15,
                value: 'is_inactive',
                label: 'Active status',
                editRecord: false,
                viewRecord: true,
                viewMode: false,
                formatter: (val, record) => {
                    const scheduled = record.notifications_valid_from && moment(user?.serverTime).diff(moment(record.notifications_valid_from))
                    const expired = record.notifications_valid_till && moment(user?.serverTime).diff(moment(record.notifications_valid_till))
                    return <div className={!val && scheduled && scheduled < 0 ? 'table_scheduled' : !val && expired && expired > 0 ? 'table_expired' : !val ? 'table_active' : 'table_inactive'}>{!val && scheduled && scheduled < 0 ? 'Scheduled' : !val && expired && expired > 0 ? 'Expired' : !val ? 'Active' : 'Inactive'}</div>
                },
                type: 'text'
            },
            // {
            //     id: 15,
            //     value: 'reply',
            //     label: 'Reply',
            //     editRecord: true,
            //     viewRecord: true,
            //     viewMode: false,
            //     fullWidth: true,
            //     disableFormatter: true,
            //     formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
            //     sort: false,
            //     type: 'textarea'
            // },
            {
                id: 16,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'actions',
                type: 'text'
            }
        ],
        replyCommentsColumns: (role, record, { user }) => [
            {
                id: 1,
                value: 'id',
                label: 'ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 2,
                value: 'vendor_id',
                label: 'Vendor ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 3,
                value: 'posts_id',
                label: 'Posts ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 4,
                value: 'user_id',
                label: 'User ID',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 5,
                value: 'comments',
                label: 'Comment',
                editRecord: true,
                viewRecord: true,
                viewMode: false,
                fullWidth: true,
                disableFormatter: true,
                sort: false,
                formatter: (val) => val && val.length > 20 ? val.substring(0, 20) + ' ....' : val,
                type: 'textarea'
            },
            {
                id: 6,
                value: 'rely_available',
                label: 'Reply available',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 7,
                value: 'is_deleted',
                label: 'Deleted',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 9,
                value: 'creator_name',
                label: 'Added By',
                editRecord: false,
                viewRecord: true,
                viewMode: false,
                type: 'text'
            },
            {
                id: 10,
                value: 'createdBy',
                label: 'Created By',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 11,
                value: 'creatorRole',
                label: 'Created Role',
                editRecord: false,
                viewRecord: false,
                viewMode: false,
                type: 'text'
            },
            {
                id: 12,
                value: 'createdAt',
                label: 'Created Date',
                editRecord: false,
                viewRecord: true,
                viewMode: false,
                formatter: (val) => moment(val).format('MM/DD/YYYY'),
                type: 'text'
            },
            {
                id: 13,
                value: 'updatedAt',
                label: 'Last Updated',
                editRecord: false,
                viewRecord: true,
                viewMode: false,
                formatter: (val) => moment(val).format('MM/DD/YYYY (hh:mm A)'),
                type: 'text'
            },
            {
                id: 8,
                value: 'is_inactive',
                label: 'Active Status',
                editRecord: false,
                viewRecord: true,
                viewMode: false,
                formatter: (val, record) => {
                    const scheduled = record.notifications_valid_from && moment(user?.serverTime).diff(moment(record.notifications_valid_from))
                    const expired = record.notifications_valid_till && moment(user?.serverTime).diff(moment(record.notifications_valid_till))
                    return <div className={!val && scheduled && scheduled < 0 ? 'table_scheduled' : !val && expired && expired > 0 ? 'table_expired' : !val ? 'table_active' : 'table_inactive'}>{!val && scheduled && scheduled < 0 ? 'Scheduled' : !val && expired && expired > 0 ? 'Expired' : !val ? 'Active' : 'Inactive'}</div>
                },
                type: 'text'
            },
            {
                id: 14,
                value: 'actions',
                label: 'Actions',
                editRecord: false,
                viewRecord: true,
                sort: false,
                editFormatter: 'modal',
                type: 'text'
            }
        ],
        children: [
            {
                name: 'edit',
                title: 'Comments',
                path: '/comments/:id',
                visible: true,
                component: EditRecordPage
            }
        ]
    }
]


export default schema